import { Typography, Link, Fade } from "@material-ui/core";
import React from "react";
import firebase from "js/firebase";

import getLogger, { LogGroup } from "js/core/logger";
import * as analytics from "js/analytics";
import Api from "js/core/api";
import { trackActivity } from "js/core/utilities/utilities";
import { getStaticUrl, serverUrl } from "js/config";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { FormIcon, StandalonePage, StandaloneForm, FormTitle, StandaloneDialogContent, FormContent, ContentWrapper } from "./Standalone";
import { ProgressButton } from "../../components/ProgressButton";

const logger = getLogger(LogGroup.AUTH);

const checkIcon = getStaticUrl("/images/auth/done-check.gif");

export class VerifyEmailReturn extends React.Component {
    state = {
        verified: null
    };

    async componentDidMount() {
        const code = new URL(window.location).searchParams.get("code");
        const uid = new URL(window.location).searchParams.get("uid");
        const isPPT = new URL(window.location).searchParams.get("isPPT");
        const isPPTBoolean = isPPT ? isPPT !== "false" : false;

        if (uid !== firebase.auth().currentUser.uid) {
            window.location = `/logout?continue=${encodeURIComponent(window.location.pathname + window.location.search)}`;
            return;
        }

        // submit the code to our API and see if its valid
        const res = await Api.verifyEmail.get({ code });

        // if it was, pull the updated firebase user
        if (res.verified) {
            // firebase needs a little time
            while (!firebase.auth().currentUser.emailVerified) {
                await firebase.auth().currentUser.reload();
            }
            await firebase.auth().currentUser.getIdToken(true);

            trackActivity("SignupFlow", "NavForward", null, null, {
                experiment_id: "FD081BDC35F0EDCADAA156ABA8CB55F6",
                step_number: 2,
                step_name: "Verification",
                verification_type: "email",
                experiment_group_assignment: "A",
                object: "button",
                object_label: "Verify Your Email",
                action: "clicked",
                workspace_id: "all"
            }, { audit: true });
        }

        this.setState({ verified: res.verified, reason: res.reason, isPPT: isPPTBoolean });
    }

    render() {
        const { verified, reason, isPPT } = this.state;

        if (verified === null) {
            return null;
        } else if (verified && isPPT) {
            return <PPTVerifyEmailSuccess {...this.props} />;
        } else if (verified) {
            return <VerifyEmailSuccess {...this.props} continueUrl={this.props.continueUrl} />;
        } else {
            return <VerifyEmailFailure {...this.props} continueUrl={this.props.continueUrl} reason={reason} />;
        }
    }
}

export const VerifyEmailSuccess = withFirebaseUser(class VerifyEmailSuccessImpl extends React.Component {
    state = {};

    async componentDidMount() {
        setTimeout(() => {
            this.setState({ fadeOut: true });
            setTimeout(() => {
                // user is comming from signup
                analytics.trackState({
                    "is_signing_up": true,
                });
                this.props.nextStep();
            }, 750);
        }, 1500);
    }

    render() {
        return (
            <StandalonePage>
                <Fade in={!this.state.fadeOut} timeout={750}>
                    <StandaloneForm>
                        <StandaloneDialogContent style={{ paddingBottom: 45 }}>
                            <FormIcon src={checkIcon} />
                            <FormTitle>Great! Your email has been verified.</FormTitle>
                        </StandaloneDialogContent>
                    </StandaloneForm>
                </Fade>
            </StandalonePage>
        );
    }
});

export class PPTVerifyEmailSuccess extends React.Component {
    render() {
        return (
            <StandalonePage>
                <StandaloneForm>
                    <StandaloneDialogContent style={{ paddingBottom: 45 }}>
                        <FormIcon src={checkIcon} />
                        <ContentWrapper>
                            <FormTitle>Success! You now have access to Beautiful.ai <br/>&#8212;right in PowerPoint.</FormTitle>
                            <FormContent>You may now close this window and return to PowerPoint to continue signing up. Or, continue to your <a href={`${serverUrl}/library`}>Library</a></FormContent>
                        </ContentWrapper>
                    </StandaloneDialogContent>
                </StandaloneForm>
            </StandalonePage>
        );
    }
}

export class VerifyEmailFailure extends React.Component {
    state = {
        sentToEmail: null,
        loading: false,
        errorMessage: null
    };

    handleClickResend = async () => {
        this.setState({ loading: true });
        try {
            const { email } = await Api.verifyEmail.post();
            this.setState({ sentToEmail: email, errorMessage: null });
        } catch (err) {
            logger.error(err, "Api.verifyEmail.post() failed");
            this.setState({ errorMessage: err.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { sentToEmail, loading, errorMessage } = this.state;

        return (
            <StandalonePage>
                <StandaloneForm>
                    <StandaloneDialogContent style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <FormTitle>This email verification link {this.props.reason === "expired" ? "has expired" : "is invalid"}</FormTitle>
                        <div style={{ paddingTop: 30, textAlign: "center" }}>
                            {sentToEmail && !errorMessage && <div>Sent to {sentToEmail}</div>}
                            {!sentToEmail && errorMessage && <div style={{ marginBottom: 10 }}>Error: {errorMessage}</div>}
                            {!sentToEmail && <ProgressButton
                                variant="contained"
                                color="primary"
                                onClick={this.handleClickResend}
                                loading={loading}
                            >
                                Resend Email
                            </ProgressButton>}
                        </div>
                        <Typography variant="body2" align="center" style={{ paddingTop: 20 }}>
                            Having trouble? <Link
                                href="mailto:support@beautiful.ai"
                                variant="body2"
                            >Contact support</Link>
                        </Typography>
                    </StandaloneDialogContent>
                </StandaloneForm>
            </StandalonePage>
        );
    }
}
