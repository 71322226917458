import React, { useEffect, useState } from "react";
import { StandalonePage, StandaloneForm } from "../Standalone";
import ProgressBar from "js/react/components/ProgressBar";
import { Button, IconButton } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import styled, { keyframes, css } from "styled-components";
import { getStaticUrl } from "js/config";

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeout = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const CompanySizePaneContainer = styled.div`
    display: flex;
    width: 650px;
    height: 488px;
    padding-bottom: 103px;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 9px 37px 0px rgba(0, 0, 0, 0.20);

    @media (max-height: 650px) {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    @media (max-width: 768px) {
        width: calc(100vw - 20px);
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650px;
    height: 50px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const LogoImage = styled.img`
    width: 100px;
    height: 16px;
    margin-top: 17px;
    margin-bottom: 17px;
    margin-right: 275px;

    @media (max-width: 768px) {
        margin-left: 30%;
    }
`;

const ProgressBarContainer = styled.div`
    display: flex;
    width: 100%;
    height: 3px;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
`;

const CompanySizeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 150px;

    @media (max-width: 768px) {
        margin-top: 90px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HeaderText = styled.div`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SubHeaderText = styled.h3`
    color: #999999;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    margin-top: 5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const OptionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    width: 500px;
    justify-items: center;
    align-items: center;

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
    }
`;

const OptionButton = styled(Button)`
    width: 55px;
    padding: 10px;
    border-radius: 1px;
    background: #FFF;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;
    gap: 10px;
    width: 100%;
    height: 100%;
`;

const OptionName = styled.span`
    color: #999999;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
`;

const CompanySize = ({ onNext, onBack, prevProgress, currProgress, handleChange, value }) => {
    const [selectedOption, setSelectedOption] = useState(value);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setFadeOut] = useState(false);

    const calculateDelay = index => {
        return 0.6 + 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(currProgress);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (key, value) => {
        handleChange(key, value);
        setSelectedOption(value);
        setFadeOut(true);
        setTimeout(() => {
            onNext();
        }, 500);
    };

    const handleBackButtonClick = () => {
        setFadeOut(true);
        setTimeout(() => {
            onBack();
        }, 500);
    };

    return (
        <StandalonePage maxWidth={600}>
            <StandaloneForm open={true} hideBackdrop fullWidth style={{ overflow: "hidden" }}>
                <CompanySizePaneContainer>
                    <Header>
                        <IconButton
                            size="small"
                            onClick={() => handleBackButtonClick()}
                            style={{ marginLeft: "20px", width: "28px", height: "28px" }}
                        >
                            <ArrowBackIos style={{ fontSize: "16px", paddingLeft: "5px" }} />
                        </IconButton>
                        <LogoImage src={getStaticUrl("/images/onboarding/beautiful-header.svg")} alt="Logo" />
                    </Header>
                    <ProgressBarContainer>
                        <ProgressBar progress={progress} prevProgress={prevProgress} />
                    </ProgressBarContainer>
                    <CompanySizeContainer>
                        <HeaderContainer>
                            <HeaderText data-test-id="company-size-header" fadeOut={fadeOut} delay={calculateDelay(0)}>How big is your company?</HeaderText>
                            <SubHeaderText fadeOut={fadeOut} delay={calculateDelay(1)}>This helps us personalize your experience</SubHeaderText>
                        </HeaderContainer>
                        <OptionsContainer>
                            <OptionButton
                                selected={selectedOption === "1" ? true : false}
                                onClick={() => handleOptionClick("companySize", "1")}
                                fadeOut={fadeOut}
                                style={{
                                    border: selectedOption === "1" ? "2px solid #11A9E2" : "0.5px solid #999",
                                    height: "39px"
                                }}
                                delay={calculateDelay(2)}
                            >
                                <OptionName>Just me</OptionName>
                            </OptionButton>
                            <OptionButton
                                selected={selectedOption === "2-19" ? true : false}
                                onClick={() => handleOptionClick("companySize", "2-19")}
                                fadeOut={fadeOut}
                                style={{
                                    border: selectedOption === "2-19" ? "2px solid #11A9E2" : "0.5px solid #999",
                                    height: "39px"
                                }}
                                delay={calculateDelay(3)}
                            >
                                <OptionName>2-19</OptionName>
                            </OptionButton>
                            <OptionButton
                                selected={selectedOption === "20-49" ? true : false}
                                onClick={() => handleOptionClick("companySize", "20-49")}
                                fadeOut={fadeOut}
                                style={{
                                    border: selectedOption === "20-49" ? "2px solid #11A9E2" : "0.5px solid #999",
                                    height: "39px"
                                }}
                                delay={calculateDelay(4)}
                            >
                                <OptionName>20-49</OptionName>
                            </OptionButton>
                            <OptionButton
                                selected={selectedOption === "50-99" ? true : false}
                                onClick={() => handleOptionClick("companySize", "50-99")}
                                fadeOut={fadeOut}
                                style={{
                                    border: selectedOption === "50-99" ? "2px solid #11A9E2" : "0.5px solid #999",
                                    height: "39px"
                                }}
                                delay={calculateDelay(5)}
                            >
                                <OptionName>50-99</OptionName>
                            </OptionButton>
                            <OptionButton
                                selected={selectedOption === "100-199" ? true : false}
                                onClick={() => handleOptionClick("companySize", "100-199")}
                                fadeOut={fadeOut}
                                style={{
                                    border: selectedOption === "100-199" ? "2px solid #11A9E2" : "0.5px solid #999",
                                    height: "39px"
                                }}
                                delay={calculateDelay(6)}
                            >
                                <OptionName>100-199</OptionName>
                            </OptionButton>
                            <OptionButton
                                selected={selectedOption === "200+" ? true : false}
                                onClick={() => handleOptionClick("companySize", "200+")}
                                fadeOut={fadeOut}
                                style={{
                                    border: selectedOption === "200+" ? "2px solid #11A9E2" : "0.5px solid #999",
                                    height: "39px"
                                }}
                                delay={calculateDelay(7)}
                            >
                                <OptionName>200+</OptionName>
                            </OptionButton>
                        </OptionsContainer>
                    </CompanySizeContainer>
                </CompanySizePaneContainer>
            </StandaloneForm>
        </StandalonePage>
    );
};

export default CompanySize;
