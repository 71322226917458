import loadable from "@loadable/component";
import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { browserHistory } from "js/react/history";
import { FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";
import { FirebaseAuthInitalizer } from "js/react/views/Auth/FirebaseAuthInitalizer";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import { serverContext } from "js/config";
import SsoConsume from "./SsoConsume";
import Logout from "./Logout";
import Onboarding from "js/react/views/Onboarding/Onboarding";
import { VerifyEmailReturn } from "js/react/views/Onboarding/VerifyEmailReturn";
import sanitizeRelativeUrl from "common/utils/sanitizeRelativeUrl";
import getLogger, { LogGroup } from "js/core/logger";
import { AppView } from "common/constants";

const logger = getLogger(LogGroup.ENTRYPOINT);

const Loading = () => {
    return (
        // TODO: spinner
        <div></div>
    );
};

// IMPORTANT: if you're making changes to any of the asynchronous imports below, make sure you test
// them locally with the string-replace-loader loader disabled in webpack.dev.config.js

const LoadableAppContainer = loadable(() => import(/* webpackChunkName: "appContainer" */ "./AppContainer"), {
    fallback: <Loading />
});

const LoadableTrackView = loadable(() => import(/* webpackChunkName: "trackView" */ "./TrackView"), {
    fallback: <Loading />
});

const LoadableSlideAiDemo = loadable(() => import(/* webpackChunkName: "aiDemo" */ "./SlideAiDemo"), {
    fallback: <Loading />
});

const LoadablePlayerContainer = loadable(() => import(/* webpackChunkName: "player" */ "./PlayerContainer"), {
    fallback: <Loading />
});

const LoadablePresenter = loadable(() => import(/* webpackChunkName: "presenter" */ "./Presenter"), {
    fallback: <Loading />
});

const LoadableMeetingRoom = loadable(() => import(/* webpackChunkName: "meetingRoom" */ "./MeetingRoom"), {
    fallback: <Loading />
});

const LoadableCheckout = loadable(() => import(/* webpackChunkName: "checkout" */ "./Checkout"), {
    fallback: <Loading />
});

const LoadableCheckoutEdu = loadable(() => import(/* webpackChunkName: "checkoutEdu" */ "./CheckoutEdu"), {
    fallback: <Loading />
});

const LoadableCopyDeck = loadable(() => import(/* webpackChunkName: "copyDeck" */ "./CopyDeck"), {
    fallback: <Loading />
});

const LoadableInvite = loadable(() => import(/* webpackChunkName: "invite" */ "./Invite"), {
    fallback: <Loading />
});

const LoadableUiHandler = loadable(() => import(/* webpackChunkName: "gdrive" */ "js/react/views/GDrive/UiHandler"), {
    fallback: <Loading />
});

const LoadableSlackRedirectHandler = loadable(() => import(/* webpackChunkName: "slack" */ "js/react/views/Slack/RedirectHandler"), {
    fallback: <Loading />
});

const LoadableProvideAccessPage = loadable(() => import(/* webpackChunkName: "provideAccessPage" */ "./ProvideAccessPage"), {
    fallback: <Loading />
});

const LoadableOauth2Auth = loadable(() => import(/* webpackChunkName: "oauth2Auth" */ "./Oauth2Auth"), {
    fallback: <Loading />
});

function getRedirectSearch(searchParams) {
    const allowedParams = ["roomID"];

    const filteredSearchParams = new URLSearchParams();
    allowedParams.forEach(allowedParamName => {
        if (searchParams.has(allowedParamName)) {
            filteredSearchParams.append(allowedParamName, searchParams.get(allowedParamName));
        }
    });

    if (Array.from(filteredSearchParams.keys()).length === 0) {
        return "";
    }

    return `?${filteredSearchParams.toString()}`;
}

export default function App() {
    useEffect(() => {
        logger.info("[App] mounted");
    }, []);

    // only valid if page was directly navigated to
    const continueUrl = sanitizeRelativeUrl(new URLSearchParams(window.location.search).get("continue") ?? "/");

    return (
        <Router history={browserHistory}>
            <Switch>
                <Route path={"/logout"}>
                    <Logout />
                </Route>
                <Route path="*">
                    <FirebaseAuthProvider>
                        <Switch>
                            <Route path={"/signup"} render={({ location }) => {
                                const searchParams = new URLSearchParams(location.search);
                                return (
                                    <Authenticate
                                        defaultPage="createAccount"
                                        affiliate={searchParams.get("affiliate")}
                                        popup={searchParams.get("popup")}
                                        reason="signup"
                                    >
                                        <Redirect to={`/${getRedirectSearch(searchParams)}`} />
                                    </Authenticate>
                                );
                            }} />
                            <Route path={"/login"} render={({ location }) => {
                                const searchParams = new URLSearchParams(location.search);
                                return (
                                    <Authenticate
                                        defaultPage="signIn"
                                        popup={searchParams.get("popup")}
                                        reason="login"
                                    >
                                        <Redirect to={`/${getRedirectSearch(searchParams)}`} />
                                    </Authenticate>
                                );
                            }} />

                            <Route path={"/verify"}>
                                <Authenticate defaultPage="signIn" noVerify>
                                    <VerifyEmailReturn nextStep={() => {
                                        if (continueUrl.startsWith("/invite/") ||
                                            continueUrl.startsWith("/teaminvite/") ||
                                            continueUrl.startsWith("/player/") ||
                                            continueUrl.startsWith("/provisioning/oauth2/auth")) {
                                            // send the request to the server
                                            window.location = continueUrl;
                                        } else {
                                            browserHistory.push(continueUrl);
                                        }
                                    }} />
                                </Authenticate>
                            </Route>
                            <Route path={["/player/:id/:slideIndex?", "/deck/:id/:slideIndex?"]} >
                                {({ match: { params: { id, slideIndex } } }) => (
                                    <FirebaseAuthInitalizer>
                                        <LoadableTrackView view={AppView.PLAYER}>
                                            <LoadablePlayerContainer id={id} slideIndex={slideIndex} />
                                        </LoadableTrackView>
                                    </FirebaseAuthInitalizer>
                                )}
                            </Route>
                            <Route path={"/meetingRoom"}>
                                <FirebaseAuthInitalizer>
                                    <LoadableMeetingRoom />
                                </FirebaseAuthInitalizer>
                            </Route>
                            <Route path={"/presenter"}>
                                <FirebaseAuthInitalizer>
                                    <LoadablePresenter />
                                </FirebaseAuthInitalizer>
                            </Route>
                            <Route path={"/checkout"} render={({ location }) => {
                                const searchParams = new URLSearchParams(location.search);
                                return (
                                    <Authenticate defaultPage="createAccount" reason="checkout">
                                        <Onboarding isCheckout>
                                            <LoadableCheckout
                                                plan={searchParams.get("plan")}
                                                enterpriseInviteId={searchParams.get("enterpriseInviteId")}
                                                billingInterval={searchParams.get("billingTerm")}
                                                priceId={searchParams.get("price")}
                                                displayName={searchParams.get("displayName")}
                                                quantity={searchParams.has("quantity") ? parseInt(searchParams.get("quantity")) : undefined}
                                            />
                                        </Onboarding>
                                    </Authenticate>
                                );
                            }} />
                            <Route path={"/checkout-edu"}>
                                <Authenticate defaultPage="createAccount">
                                    <LoadableCheckoutEdu />
                                </Authenticate>
                            </Route>
                            <Route path={"/copyDeck/:presentationId/:name?"} render={({ location }) => {
                                const searchParams = new URLSearchParams(location.search);
                                return (
                                    <Authenticate
                                        defaultPage="createAccount"
                                        popup={searchParams.get("popup")}
                                        reason="copy-deck"
                                    >
                                        <Onboarding>
                                            <LoadableCopyDeck />
                                        </Onboarding>
                                    </Authenticate>
                                );
                            }} />
                            <Route path={"/invite/:token"}>
                                <Authenticate
                                    defaultPage={serverContext.form == "login" ? "signIn" : "createAccount"}
                                    readonlyEmail={serverContext.email}
                                    presentationPermission={serverContext.presentationPermission}
                                    presentationName={serverContext.presentationName}
                                    presentationSharedBy={serverContext.presentationSharedBy}
                                    presentationThumbnailUrl={serverContext.presentationThumbnailUrl}
                                    reason="presentation-invite"
                                >
                                    <LoadableInvite />
                                </Authenticate>
                            </Route>
                            <Route path={"/teaminvite/:token"}>
                                <Authenticate
                                    defaultPage={serverContext.form == "login" ? "signIn" : "createAccount"}
                                    readonlyEmail={serverContext.email}
                                    teamName={serverContext.teamName}
                                    reason="team-invite"
                                >
                                    <LoadableInvite />
                                </Authenticate>
                            </Route>
                            <Route path={["/api/sso/saml/consume", "/testing/fast-login", "/provisioning/signIn"]}>
                                <FirebaseAuthInitalizer>
                                    <SsoConsume />
                                </FirebaseAuthInitalizer>
                            </Route>
                            <Route path={"/gdrive/uihandler"}>
                                <Authenticate defaultPage="signIn" reason="gdrive" showOnlyGoogle>
                                    <LoadableUiHandler />
                                </Authenticate>
                            </Route>
                            <Route path={"/slack/redirect"}>
                                <Authenticate defaultPage="signIn" reason="slack">
                                    <LoadableSlackRedirectHandler />
                                </Authenticate>
                            </Route>
                            <Route path={"/provideaccess/:notificationId"}>
                                <Authenticate defaultPage="signIn">
                                    <LoadableProvideAccessPage />
                                </Authenticate>
                            </Route>
                            <Route path={"/slideAiDemo"}>
                                <LoadableSlideAiDemo />
                            </Route>
                            <Route path={"/provisioning/oauth2/auth"}>
                                <FirebaseAuthInitalizer>
                                    <LoadableOauth2Auth />
                                </FirebaseAuthInitalizer>
                            </Route>
                            <Route path="*">
                                <Authenticate defaultPage="signIn">
                                    <Onboarding>
                                        <LoadableAppContainer />
                                    </Onboarding>
                                </Authenticate>
                            </Route>
                        </Switch>
                    </FirebaseAuthProvider>
                </Route>
            </Switch>
        </Router>
    );
}
