import React, { useEffect, useState } from "react";
import { Icon, Button } from "@material-ui/core";
import { BusinessCenterOutlined, SchoolOutlined, FormatPaintOutlined } from "@material-ui/icons";
import styled, { keyframes, css } from "styled-components";
import { StandalonePage, StandaloneForm } from "../Standalone";
import ProgressBar from "js/react/components/ProgressBar";
import { getStaticUrl } from "js/config";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeout = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const UserPaneContainer = styled.div`
    display: flex;
    width: 650px;
    height: 488px;
    padding-bottom: 103px;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 9px 37px 0px rgba(0, 0, 0, 0.20);

    @media (max-height: 650px) {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    @media (max-width: 768px) {
        width: calc(100vw - 20px);
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650px;
    height: 50px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const LogoImage = styled.img`
    width: 100px;
    height: 16px;
    margin-top: 17px;
    margin-bottom: 17px;
    margin-left: 275px;

    @media (max-width: 768px) {
        margin-left: 40%;
    }
`;

const ProgressBarContainer = styled.div`
    display: flex;
    width: 100%;
    height: 3px;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
`;

const UserTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 66px;

    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HeaderText = styled.div`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SubHeaderText = styled.h3`
    color: #999999;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    margin-top: 5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const OptionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 500px;
    justify-items: center;
    align-items: center;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }

    // Center the single element in the second row
    & > :nth-child(4) {
        grid-column: 2 / 3;
    }
`;

const OptionButton = styled(Button)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 164px;
    height: 63px;
    border-radius: 1px;
    background: #FFF;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;
`;

const OptionImage = styled(Icon)`
    position: absolute;
    top: 0;
    left: 0; 
    margin: 10px 0 0 10px;
    color: #11A9E2;
    font-size: 20px;
`;

const OptionName = styled.span`
    position: absolute;
    color: #999999;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    text-align: left;
    margin-top: 20px;

    /* Safari-specific styles */
    ${isSafari && `
        margin-top: 5px;
    `}
`;

const UserType = ({ onNext, prevProgress, currProgress, handleChange, value, skipSurvey }) => {
    const [selectedOption, setSelectedOption] = useState(value);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setfadeOut] = useState(false);

    const calculateDelay = index => {
        return 0.6 + 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(currProgress);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (key, value) => {
        handleChange(key, value);
        setSelectedOption(value);
        setfadeOut(true);
        setTimeout(() => {
            onNext();
        }, 500);
    };

    return (
        <StandalonePage maxWidth={600}>
            <StandaloneForm open={true} hideBackdrop fullWidth style={{ overflow: "hidden" }}>
                <UserPaneContainer>
                    <Header>
                        <LogoImage src={getStaticUrl("/images/onboarding/beautiful-header.svg")} alt="Logo" />
                    </Header>
                    <ProgressBarContainer>
                        <ProgressBar progress={progress} prevProgress={prevProgress} />
                    </ProgressBarContainer>
                    <UserTypeContainer>
                        <HeaderContainer>
                            <HeaderText data-test-id="user-type-header" fadeOut={fadeOut ? fadeOut : undefined} delay={calculateDelay(0)}>What kind of work do you do?</HeaderText>
                            <SubHeaderText fadeOut={fadeOut ? fadeOut : undefined} delay={calculateDelay(1)}>This helps us recommend templates</SubHeaderText>
                        </HeaderContainer>
                        <OptionsContainer>
                            <OptionButton
                                onClick={() => handleOptionClick("userType", "sales")}
                                delay={calculateDelay(2)}
                                style={{
                                    border: selectedOption === "sales" ? "2px solid #11A9E2" : "0.5px solid #999"
                                }}
                                fadeOut={fadeOut ? fadeOut : undefined}
                            >
                                <OptionImage as={BusinessCenterOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                <OptionName>Sales</OptionName>
                            </OptionButton>
                            <OptionButton
                                onClick={() => handleOptionClick("userType", "marketing")}
                                delay={calculateDelay(3)}
                                style={{
                                    border: selectedOption === "marketing" ? "2px solid #11A9E2" : "0.5px solid #999"
                                }}
                                fadeOut={fadeOut ? fadeOut : undefined}
                            >
                                <OptionImage as={FormatPaintOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                <OptionName>Marketing</OptionName>
                            </OptionButton>
                            <OptionButton
                                onClick={() => handleOptionClick("userType", "educator-or-student")}
                                delay={calculateDelay(4)}
                                style={{
                                    border: selectedOption === "educator-or-student" ? "2px solid #11A9E2" : "0.5px solid #999"
                                }}
                                fadeOut={fadeOut ? fadeOut : undefined}
                            >
                                <OptionImage as={SchoolOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                <OptionName>Educator or student</OptionName>
                            </OptionButton>
                            <OptionButton
                                onClick={() => handleOptionClick("userType", "other")}
                                delay={calculateDelay(5)}
                                style={{
                                    border: selectedOption === "other" ? "2px solid #11A9E2" : "0.5px solid #999"
                                }}
                                fadeOut={fadeOut ? fadeOut : undefined}
                            >
                                <OptionImage style={{ fontSize: "20px" }}>more_horiz</OptionImage>
                                <OptionName>Other</OptionName>
                            </OptionButton>
                        </OptionsContainer>
                    </UserTypeContainer>
                </UserPaneContainer>
                <Button
                    id="skip-survey"
                    variant="contained"
                    onClick={skipSurvey}
                    style={{
                        display: "none",
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                        width: "3px",
                        height: "3px",
                        opacity: "0"
                    }}
                />
            </StandaloneForm>
        </StandalonePage>
    );
};

export default UserType;
